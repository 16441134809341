<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">L'entreprise</h1>
        </div>
        <div class="history d-flex flex-row">
            <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/10/Page-Entreprise-photo-FG.jpg" id="history-img"
                 alt="Florian Gerbron"/>
            <div class="d-flex flex-column txt-history">
                <h2>LE MOT DU DIRECTEUR</h2>
                <p>« Bonjour à tous,
                    <br>
                    <br>
                    Depuis maintenant plus de 20 ans, Duhamel Logistique a su démontrer son savoir-faire dans les
                    différents métiers de la logistique.
                    Un savoir-faire acquis par nos collaborateurs pour satisfaire au mieux une clientèle provenant
                    d’horizons divers.<br><br>

                    Avant d’être un logisticien au service de ses clients, Duhamel Logistique est une entreprise
                    familiale de proximité. La communication, l’efficacité et l’engagement font partie intégrante de
                    notre stratégie.<br>
                    L’idée principale de notre entreprise est de proposer à nos clients un « full-service » logistique
                    et transport, nous nous positionnons clairement en tant que 4 PL.<br>
                    Dans cette optique, nous développons sans cesse de nouvelles offres afin de garantir à nos
                    partenaires un service de qualité qui s’adapte à leurs besoins.<br><br>

                    En plus de nos valeurs, ciment de notre histoire et de notre croissance, nous favorisons au maximum
                    l’écoute de nos clients et de nos salariés lors de la collaboration.
                    Le parti pris de Duhamel Logistique est de transformer les projets de nos clients en une aventure
                    humaine performante.<br><br>

                    À travers notre site internet, vous aurez l’opportunité de découvrir nos coulisses, nos métiers et
                    notre savoir-faire, je vous souhaite à tous, une excellente visite ! »<br><br>

                    Florian GERBRON<br>
                    Directeur Général

                </p>
            </div>
        </div>

      <div class="bandeaubref"></div>

        <div class="bamboo">
            <h3>DUHAMEL LOGISTIQUE EN BREF </h3>
            <p>
                Le groupe Duhamel est fondé en 1965. C’est en 1997, avec l’arrivée d’Hugues Duhamel, le fils du
                fondateur, que la logistique prend toute sa dimension. L’activité démarre avec 3000 m² d’entrepôt, 4
                salariés et 3 clients de la région.
                23 ans plus tard, Duhamel Logistique compte 9 entrepôts , 130 clients à travers le monde et 130
                salariés. Entre 2013 et 2018, l’entreprise double son chiffre d’affaires, son nombre de clients et de
                salariés, ouvre 3 nouveaux entrepôts et devient alors un acteur incontournable de la prestation
                logistique de proximité.
            </p>
        </div>
        <div class="bandeau">
            <div class="bandeau_content">
                <h3 class="bandeau_title">NOS MÉTIERS</h3>
                <p class="bandeau_txt">
                    Nous apportons à nos clients un service de gestion de stock, de conditionnement, de préparation de
                    commandes, de gestion des matières dangereuses et d’expédition vers la France et l’international.
                    Nous
                    leur proposons des prestations logistiques de produits de luxe, de e-commerce, de logistique
                    industrielle
                    et de copacking.
                </p>
            </div>
        </div>
        <div class="chiffres">
            <div class="d-flex txt-container chiffre_container">
                <h3 class="chiffre_title">NOS CHIFFRES CLÉS :</h3>

                <div class="client">
                    <img class="client-icon" src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/team.png"
                         alt="Groupe">
                    <number
                            class="nb-clients"
                            :class="{scaleBig: scaleClass}"
                            ref="number2"
                            :from="numberFrom1"
                            :format="theFormat"
                            :to="numberTo1"
                            :duration="duration"
                            easing="Power4.easeOut"
                            @complete="completed"/>
                    <span class="txt-clients">CLIENTS</span>
                </div>

                <div class="salarie">
                    <img class="salarie-icon"
                         src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/smile.png"
                         alt="Groupe">
                    <number
                            class="nb-salarie"
                            :class="{scaleBig: scaleClass}"
                            ref="number2"
                            :from="numberFrom2"
                            :format="theFormat"
                            :to="numberTo2"
                            :duration="duration"
                            easing="Power4.easeOut"
                            @complete="completed"/>
                    <span class="txt-salarie">SALARIÉS</span>
                </div>

                <div class="surface">
                    <img class="surface-icon"
                         src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/supply.png"
                         alt="Groupe">
                    <number
                            class="nb-surface"
                            :class="{scaleBig: scaleClass}"
                            ref="number2"
                            :from="numberFrom3"
                            :format="theFormat"
                            :to="numberTo3"
                            :duration="duration"
                            easing="Power4.easeOut"
                            @complete="completed"/>
                    <span class="txt-surface">M² DE SURFACE DE STOCKAGE</span>
                </div>

                <div class="ca">
                    <img class="ca-icon" src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/up.png"
                         alt="Groupe">
                    <number
                            class="nb-ca"
                            :class="{scaleBig: scaleClass}"
                            ref="number2"
                            :from="numberFrom4"
                            :format="theFormat"
                            :to="numberTo4"
                            :duration="duration"
                            easing="Power4.easeOut"
                            @complete="completed"/>
                    <span class="txt-ca">€ DE CA EN 2019</span>
                </div>

                <div class="egalite">
                    <img class="egal-icon" src="https://api.duhamel-logistique.fr/wp-content/uploads/2021/01/hommefemme.png"
                         alt="Groupe">
                    <span class="nb-egal">99/100</span>
                    <span class="txt-egal">INDEX ÉGALITÉ H-F</span>
                </div>

                <div class="transport">
                    <img class="transport-icon"
                         src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/gears.png"
                         alt="Groupe">
                    <number
                            class="nb-transport"
                            :class="{scaleBig: scaleClass}"
                            ref="number2"
                            :from="numberFrom5"
                            :format="theFormat"
                            :to="numberTo5"
                            :duration="duration"
                            easing="Power4.easeOut"
                            @complete="completed"/>
                    <span class="txt-transport">PARTENAIRES TRANSPORT</span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Entreprise',
        data() {
            return {
                numberFrom1: 0,
                numberTo1: 130,
                duration: 5,
                scaleClass: false,
                numberFrom2: 0,
                numberTo2: 130,
                numberFrom3: 0,
                numberTo3: 138000,
                numberFrom4: 0,
                numberTo4: 37152145,
                numberFrom5: 0,
                numberTo5: 25,
                numberFrom6: 0,
                numberTo6: 99,
            };
        },
        methods: {
            theFormat(number) {
                return  Intl.NumberFormat('fr-FR', {maximumSignificantDigits: 3}).format(number)
            },
            completed() {
                console.log("animation finish");
            }
        },
    };
</script>


<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/2018-08-Duhamel-logistique-inauguration-site-9_57-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 59%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .history {
        background-color: #F6F6F6;
        padding-top: 15px;
    }

    #history-img {
        height: 155px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .txt-history {
        text-align: left;
        margin-right: 15px;
        margin-bottom: 15px;
    }

    h2, h3 {
        font-size: 18px;
        font-weight: 900;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    .bamboo {
        margin: 15px;
        text-align: left;
    }

    .bandeau {
        width: 100%;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/25258521033_3807fffd55_h.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 32%;
        padding: 15px 0px;
    }

    .bandeau_content {
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .chiffres {
        margin: 15px;
    }

    .chiffre_container {
        flex-direction: column;
    }

    .chiffre_title {
        width: 100%;
    }

    .client {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .client-icon {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 10%;
        margin-left: 45%;
    }

    .nb-clients {
        font-weight: 600;
        font-size: 36px;
    }

    .txt-clients {
        font-size: 13px;
        font-weight: 400;
    }

    .salarie {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .salarie-icon {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 10%;
        margin-left: 45%;
    }

    .nb-salarie {
        font-weight: 600;
        font-size: 36px;
    }

    .txt-salarie {
        font-size: 13px;
        font-weight: 400;
    }

    .surface {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .surface-icon {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 10%;
        margin-left: 45%;
    }

    .nb-surface {
        font-weight: 600;
        font-size: 36px;
    }

    .txt-surface {
        font-size: 13px;
        font-weight: 400;
    }

    .ca {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .ca-icon {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 10%;
        margin-left: 45%;
    }

    .nb-ca {
        font-weight: 600;
        font-size: 36px;
    }

    .txt-ca {
        font-size: 13px;
        font-weight: 400;
    }

    .egalite {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .egal-icon {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 10%;
        margin-left: 45%;
    }

    .nb-egal {
        font-weight: 600;
        font-size: 36px;
    }

    .txt-egal {
        font-size: 13px;
        font-weight: 400;
    }

    .transport {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .transport-icon {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 10%;
        margin-left: 45%;
    }

    .nb-transport {
        font-weight: 600;
        font-size: 36px;
    }

    .txt-transport {
        font-size: 13px;
        font-weight: 400;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        #history-img {
            height: 215px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .txt-history {
            margin-right: 20px;
            margin-bottom: 20px;
        }

        .bamboo {
            margin: 20px;
        }

        .bandeau_content {
            padding: 20px;
        }

        .chiffres {
            margin: 20px;
        }

        .client-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .salarie-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .surface-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .ca-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .egal-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .transport-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        #history-img {
            height: 305px;
            margin-left: 30px;
            margin-right: 30px;
        }

        .txt-history {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        .bamboo {
            margin: 30px;
        }

        .bandeau {
            padding: 30px 15px;
        }

        .bandeau_content {
            padding: 30px;
        }

        .chiffres {
            margin: 30px;
        }

        .chiffre_container {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .client {
            width: 33%;
            margin-bottom: 20px;
        }

        .client-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .salarie {
            width: 33%;
            margin-bottom: 20px;
        }

        .salarie-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .surface {
            width: 33%;
            margin-bottom: 20px;
        }

        .surface-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .ca {
            width: 50%;
            margin-bottom: 20px;
        }

        .ca-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .egalite {
            width: 50%;
            margin-bottom: 20px;
        }

        .egal-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }

        .transport {
            width: 50%;
            margin-bottom: 20px;
        }

        .transport-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 10%;
            margin-left: 45%;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        #history-img {
            height: 385px;
            margin-left: 100px;
            margin-right: 40px;
        }

        .txt-history {
            margin-right: 40px;
            margin-bottom: 40px;
        }

        .bamboo {
            margin: 40px 60px;
        }

        .bandeau {
            padding: 40px 20px;
        }

        .bandeau_content {
            padding: 40px;
        }

        .chiffres {
            margin: 40px;
        }

        .client {
            width: 33%;
            margin-bottom: 30px;
        }

        .client-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 15%;
            margin-left: 43%;
        }

        .salarie {
            width: 33%;
            margin-bottom: 30px;
        }

        .salarie-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 15%;
            margin-left: 43%;
        }

        .surface {
            width: 33%;
            margin-bottom: 30px;
        }

        .surface-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 15%;
            margin-left: 43%;
        }

        .ca {
            width: 50%;
            margin-bottom: 30px;
        }

        .ca-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 15%;
            margin-left: 43%;
        }

        .egalite {
            width: 50%;
            margin-bottom: 30px;
        }

        .egal-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 15%;
            margin-left: 43%;
        }

        .transport {
            width: 50%;
            margin-bottom: 30px;
        }

        .transport-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 15%;
            margin-left: 43%;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        #history-img {
            height: 505px;
            margin-left: 350px;
            margin-right: 50px;
        }

        .txt-history {
            margin-right: 350px;
            margin-bottom: 50px;
        }

        .bamboo {
            margin: 50px 300px;
        }

        .bandeau {
            padding: 50px 400px;
        }

        .bandeau_content {
            padding: 50px;
        }

        .chiffres {
            margin: 50px;
        }

        .client {
            width: 33%;
            margin-bottom: 40px;
        }

        .client-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 20%;
            margin-left: 40%;
        }

        .salarie {
            width: 33%;
            margin-bottom: 40px;
        }

        .salarie-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 20%;
            margin-left: 40%;
        }

        .surface {
            width: 33%;
            margin-bottom: 40px;
        }

        .surface-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 20%;
            margin-left: 40%;
        }

        .ca {
            width: 33%;
            margin-bottom: 40px;
        }

        .ca-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 20%;
            margin-left: 40%;
        }

        .egalite {
            width: 33%;
            margin-bottom: 30px;
        }

        .egal-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 20%;
            margin-left: 41%;
        }

        .transport {
            width: 33%;
            margin-bottom: 40px;
        }

        .transport-icon {
            margin-top: 15px;
            margin-bottom: 5px;
            width: 20%;
            margin-left: 40%;
        }
    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        #history-img {
            height: 541px;
            margin-left: 400px;
            margin-top: 35px;
            margin-bottom: 35px;
            margin-right: 50px;
        }

        .txt-history {
            margin-right: 400px;
            margin-bottom: 50px;
            text-align: justify;
        }

        .bamboo {
            margin: 50px 400px;
        }
    }

</style>